<template>
  <figure
    class="group shadow-sliderboxes"
  >
    <div
      class="relative aspect-slider-box h-auto w-full overflow-hidden"
    >
      <UiImage
        v-if="props.imageUrl"
        :src="props.imageUrl"
        :original-width="props.imageWidth"
        :original-height="props.imageHeight"
        loading="lazy"
        fetchpriority="low"
        class="size-full object-cover transition-transform group-hover:scale-105"
      />

      <div
        v-else
        class="size-full bg-gray-100"
      />
    </div>

    <figcaption class="p-7">
      <div
        class="flex min-h-12 items-center gap-5 hyphens-auto break-words text-lg font-bold leading-6 text-blue"
        :style="{ wordBreak: 'break-word' }"
        lang="pl"
      >
        <UiIcon
          v-if="props.icon"
          :width="44"
          :height="44"
          :name="icon"
          class="shrink-0"
        />
        {{ props.header }}
      </div>

      <UiButton
        icon-right="arrow"
        variant="secondary"
        class="mt-7 w-full py-2 text-base"
        :to="props.buttonLink"
        @click="emit('click:button')"
      >
        {{ props.buttonText }}
      </UiButton>
    </figcaption>
  </figure>
</template>

<script lang="ts" setup>
import UiButton from '../UiButton/UiButton.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import UiImage from '../UiImage/UiImage.vue'

const props = defineProps<{
  imageUrl: string
  imageWidth?: number
  imageHeight?: number
  icon?: string
  header: string
  buttonText?: string
  buttonLink?: string
}>()

const emit = defineEmits<{
  (e: 'click:button'): unknown
}>()
</script>
